import { ApolloError } from '@apollo/client';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Linking, StyleSheet, Text, View } from 'react-native';
import styled from 'rn-css';
import { Button, Input, Spacing, Upload } from '..';
import { palettes } from '../../config';
import { getResponsiveStyle } from '../../utils/getResponsiveStyle';
import { PlusIcon } from '../common/icons';
import Dropdown2 from '../common/dropdown2/dropdown2';
import {
	addBrandInitalValues,
	addBrandValidationSchema,
	editBrandValidationSchema,
	TAddBrandInitalValues,
} from './FormikConstants';
import { DropzoneProps } from 'react-dropzone';
import { useSetAlert } from '../../hooks/useSetAlerts';
import useCreateNewBrand from '../../api/brands/useCreateNewBrand';
import { useCreateMedia } from '../../api/common/useCreateMedia';
import { useNavigation, useRoute } from '@react-navigation/native';
import { TDropdownObject, TUseNavigation, TUseRoute } from '../../types/types';
import { TStyle } from '../../types/TStyle';
import countryNameswithCode from '../../utils/countryNameswithCode';
import ModalComponent from '../common/modal/ModalComponent';
import useEditBrand from '../../api/brands/useEditBrand';
import Loader from '../common/loader/Loader';
import {
	TBrandAllowedCountries,
	TGetCreatedSingleBrandResponse,
	TGetEditedSingleBrandResponse,
	TGetSingleBrandResponse,
	TInputAllBrandsData,
} from '../../types/brandTypes';
import { useGetSingleBrandLazy } from '../../api/brands/useGetSingleBrand';
import { useAppSelector } from '../../redux/store';
import DropDownContainer from '../common/drop-down/drop-down';

const AddCountryConfigLink = styled.Text`
	cursor: pointer;
	border-bottom: 1px solid transparent;
	font-size: ${getResponsiveStyle(14, 'font')}px;
	line-height: ${getResponsiveStyle(14, 'font')}px;
	width: max-content;
	&:hover {
		padding-bottom: 7px;
		border-bottom: 1px solid ${palettes.primary[0]};
	}
`;

const paymentOptions = [
	{
		label: "On Delivery",
		value: "ON_DELIVERY"
	},
	{
		label: "Online",
		value: "ONLINE"
	}
]

const AddBrandForm = () => {
	const { setAlert } = useSetAlert();
	const [profile, setProfile] = useState('');
	const [country, setCountry] = useState("")
	const [additionalMedia, setAdditionalMedia] = useState<string[]>([]);
	const [brandDetails, setBrandDetails] = useState<TAddBrandInitalValues>();
	const navigator = useNavigation<TUseNavigation>();
	const route = useRoute<TUseRoute<'BrandDetails'>>();
	const brandId = route?.params?.brandId;
	const [modalState, setModalState] = useState(false);
	const changeModalState = () => {
		setModalState(!modalState);
	};
	const { BRAND_PILL_CATEGORIES, DROPDOWN_CATEGORIES, INTEREST_TAGS } =
		useAppSelector(state => state.tag);
	const [brandConnectUrl, setBrandConnectUrl] = useState('');
	const onCompletedUploadMedia = (data: TMediaResponse) => {
		if (data.createMedia.success && data.createMedia.data) {
			setAlert('Image uploaded successfully', 'normal');
			if (data.createMedia.data.alt === 'profile-image') {
				setProfile(data.createMedia.data.src);
			} else if (data.createMedia.data.alt === 'additionalmedia-image') {
				setAdditionalMedia([
					...additionalMedia,
					data.createMedia.data.src,
				]);
			}
		} else {
			setAlert('Image uploading failed', 'danger');
		}
	};
	const { uploadMedia, loading: loading_uploadMedia } = useCreateMedia({
		onCompleted: onCompletedUploadMedia,
	});
	const onDropProfileImage: DropzoneProps['onDrop'] = acceptedFiles => {
		if (acceptedFiles.length > 0) {
			uploadMedia(acceptedFiles[0], 'USER', 'profile-image');
		}
	};
	const AdditionalImage: DropzoneProps['onDrop'] = acceptedFiles => {
		if (acceptedFiles.length > 0) {
			uploadMedia(acceptedFiles[0], 'USER', 'additionalmedia-image');
		}
	};

	const onSingleCompleted = (data: TGetSingleBrandResponse) => {
		if (data && data.getBrand && data.getBrand.data) {
			let val = data.getBrand.data;
			setProfile(val?.profileImage);
			setAdditionalMedia([...additionalMedia, ...val?.additionalMedia]);
			setBrandDetails({
				socialReason: val?.socialReason ?? '',
				commercialName: val?.name ?? '',
				address1: val?.address.address1 ?? '',
				address2: '',
				city: val?.address.city ?? '',
				zipCode: val?.address.zipCode ?? '',
				country: val?.address.countryLabel ?? '',
				countryCode: val?.address.countryCode ?? '',
				region: '',
				email: val?.email ?? '',
				businessPhoneNumber: val?.phoneNumber ?? '',
				// vat: val.vat,
				vatId: val?.vatId ?? '',
				companyId: val?.companyId ?? '',
				numericSignature: val?.numericSignature ?? '',
				interestTags: val?.tags,
				// kybStatus: val?.kybStatus ?? '',
				// accountStatus: '',
				website: val?.website ?? '',
				adminNote: '',
				description: val?.description ?? '',
				brandAllowedCountries: (val?.brandAllowedCountries?.map((data) => {
					return {
						sn: data.sn,
						countryCode: data?.countryCode,
						paymentOption: data?.paymentOption,
						freeShippingThreshold: data?.freeShippingThreshold,
						shippingFee: data?.shippingFee,
						exchangeRate: data?.exchangeRate,
					}
				}) as any) ?? []
			});
		} else {
			setAlert('There was an error', 'danger');
		}
	};
	const onBrandError = (error: ApolloError) => {
		setAlert('Unable to fetch Brand', 'danger');
	};

	const { getSingleBrand, loading: brandLoading } = useGetSingleBrandLazy({
		onCompleted: onSingleCompleted,
	});

	useEffect(() => {
		brandId && getSingleBrand(brandId);
	}, [brandId]);
	const onCreateCompleted = (value: TGetCreatedSingleBrandResponse) => {
		if (value.createBrand.success && value.createBrand.data) {
			setAlert('Brand Sucessfully Created', 'normal');

			// setBrandConnectUrl(
			// 	value.createBrand.data.connectAccountInfo.lastUrl
			// );
			// setModalState(true);
		} else {
			setAlert(value.createBrand.message, 'warning');
		}
	};
	const _onCreateError = (error: ApolloError) => {
		setAlert('Unable to create New Brand', 'warning');
	};

	const { createBrand, loading: createBrandLoading } = useCreateNewBrand(
		onCreateCompleted,
		_onCreateError
	);

	const onEditCompleted = (value: TGetEditedSingleBrandResponse) => {
		if (value.editBrand.success && value.editBrand.data) {
			setAlert('Brand Sucessfully Edited', 'normal');
		} else {
			setAlert(value.editBrand.message, 'warning');
		}
	};
	const _onEditError = (error: ApolloError) => {
		setAlert('Unable to edit Brand', 'warning');
	};
	const { editBrand, loading: editBrandLoading } = useEditBrand(
		onEditCompleted,
		_onEditError
	);
	const brandAction = (args: TInputAllBrandsData) => {
		if (brandId) {
			editBrand(brandId, args);
		} else {
			createBrand(args);
		}
	};

	const combinedCategoryArr = [
		...BRAND_PILL_CATEGORIES,
		...DROPDOWN_CATEGORIES,
	];

	const uniqueCategoryArray = combinedCategoryArr.filter((value, index) => {
		const _value = JSON.stringify(value);
		return (
			index ===
			combinedCategoryArr.findIndex(obj => {
				return JSON.stringify(obj) === _value;
			})
		);
	});

	const removeDuplicateFromArrayObj = (combinedArr: TDropdownObject[]) => {
		const newArr: TDropdownObject[] = [];
		for (let i = 0; i < combinedArr.length; i++) {
			const element = combinedArr[i];
			const findElement = newArr.findIndex(
				item => item.value === element.value
			);

			if (findElement === -1) {
				newArr.push(element);
			}
		}
		return newArr;
	};

	const updateBrandAllowedBrandCountry = (
		value: string,
		values: any,
		setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
		field: string,
		index: number
	) => {
		const findCountryIndex = values.brandAllowedCountries?.findIndex((item: any) => item?.sn === (index + 1))
		const findData = values.brandAllowedCountries?.find((item: any) => item?.sn === (index + 1))
		const brandAllowedCountriesClone = [...values.brandAllowedCountries as []]
		const newData = {
			sn: (index + 1),
			countryCode: findData?.countryCode,
			paymentOption: findData?.paymentOption,
			freeShippingThreshold: findData?.freeShippingThreshold,
			shippingFee: findData?.shippingFee,
			exchangeRate: findData?.exchangeRate,
			[field]: value,
		}
		if (findCountryIndex !== -1) {
			brandAllowedCountriesClone.splice(findCountryIndex as number, 1, newData as never)
			setFieldValue('brandAllowedCountries', brandAllowedCountriesClone);
		} else {
			setFieldValue('brandAllowedCountries', [
				...values?.brandAllowedCountries as [],
				{
					sn: index + 1,
					[field]: value,
				},
			]);
		}
	}

	return brandLoading ? (
		<Loader />
	) : (
		<Formik
			initialValues={
				brandId && brandDetails ? brandDetails : addBrandInitalValues
			}
			enableReinitialize={true}
			validationSchema={addBrandValidationSchema}
			onSubmit={(values, Dropdown2) => {
				brandAction({
					name: values.commercialName,
					profileImage: profile,
					socialReason: values.socialReason,
					address: {
						address1: values.address1,
						// address2: values.address2,
						city: values.city,
						zipCode: values.zipCode,

						countryCode: values.countryCode,
						countryLabel: values.country,
						geoCodedAddress: values.zipCode,
					},
					phoneNumber: values.businessPhoneNumber,
					email: values.email,
					vatId: values.vatId as any,
					companyId: values.companyId,
					additionalMedia: additionalMedia,
					numericSignature: values.numericSignature,
					tags: values.interestTags,
					brandAllowedCountries: values.brandAllowedCountries as [],
					website: values.website,
					kybStatus: null,
					description: values.description ?? '',
				});
			}}
		>
			{({
				handleBlur,
				handleChange,
				handleSubmit,
				touched,
				errors,
				dirty,
				isValid,
				setFieldValue,
				values,
			}) => (
				<>
					<Spacing
						orientation="column"
						spacing={34}
						containerStyles={{
							marginBottom: getResponsiveStyle(50),
							zIndex: 5000,
						}}
					>
						<Upload
							label={'Profile Image'}
							hint={'Add Image'}
							containerStyles={{
								width: getResponsiveStyle(128, 'dimensions'),
								height: getResponsiveStyle(136, 'dimensions'),
								marginBottom: getResponsiveStyle(24),
							}}
							uploadIcon={<PlusIcon height={14} width={14} />}
							accept={'image/*'}
							onDrop={onDropProfileImage}
							loading={loading_uploadMedia}
						/>
						<Input
							label="Company Id*"
							containerStyles={{
								flex: 0.328,
							}}
							onChangeText={handleChange('companyId')}
							onBlur={handleBlur('companyId')}
							value={values.companyId}
							error={
								touched.companyId && errors.companyId
									? errors.companyId
									: ''
							}
						/>

						<Input
							label="Social Reason*"
							onChangeText={handleChange('socialReason')}
							onBlur={handleBlur('socialReason')}
							value={values?.socialReason}
							error={
								touched.socialReason && errors.socialReason
									? errors.socialReason
									: ''
							}
						/>

						<Input
							label="Commercial Name*"
							onChangeText={handleChange('commercialName')}
							onBlur={handleBlur('commercialName')}
							value={values?.commercialName}
							error={
								touched.commercialName && errors.commercialName
									? errors.commercialName
									: ''
							}
						/>

						<Spacing orientation="column" spacing={8}>
							<Input
								label="Address"
								onChangeText={handleChange('address1')}
								onBlur={handleBlur('address1')}
								value={values.address1}
								error={
									touched.address1 && errors.address1
										? errors.address1
										: ''
								}
							/>

							<Input
								onChangeText={handleChange('address2')}
								onBlur={handleBlur('address2')}
								value={values.address2}
								error={
									touched.address2 && errors.address2
										? errors.address2
										: ''
								}
							/>
						</Spacing>

						<Spacing withEqualWidth spacing={16}>
							<Input
								label="City*"
								onChangeText={handleChange('city')}
								onBlur={handleBlur('city')}
								value={values.city}
								error={
									touched.city && errors.city
										? errors.city
										: ''
								}
							/>

							<Input
								label="Zip Code*"
								onChangeText={handleChange('zipCode')}
								onBlur={handleBlur('zipCode')}
								value={values.zipCode}
								error={
									touched.zipCode && errors.zipCode
										? errors.zipCode
										: ''
								}
							/>
						</Spacing>

						<Dropdown2
							options={countryNameswithCode}
							key={'dropdown'}
							isMulti={false}
							isCountry
							labelProps={{
								label: 'Country*',
							}}
							values={countryNameswithCode.find(
								item => item.value === values.countryCode
							)}
							onChangeValue={valueObject => {
								setFieldValue('country', valueObject.label);
								setFieldValue('countryCode', valueObject.value);
							}}
						/>
						{/* <Dropdown2
							options={countryNameswithCode}
							key={'dropdown'}
							isMulti={false}
							isCountry
							labelProps={{
								label: 'Country*',
							}}
							values={countryNameswithCode.find(
								item => item.value === values.countryCode
							)}
							onChangeValue={valueObject => {
								setFieldValue('country', valueObject.label);
								setFieldValue('countryCode', valueObject.value);
							}}
						/> */}
						<AddCountryConfigLink
							onPress={() => {
								console.log({ brandAllowedCountries: values.brandAllowedCountries })
								setFieldValue('brandAllowedCountries', [
									...values?.brandAllowedCountries as [],
									{
										sn: (values?.brandAllowedCountries?.length as number) + 1
									},
								]);
							}}
							style={styles.countryConfig}
						>
							+ Add Allowed Shipping Countries
						</AddCountryConfigLink>


						<>
							{values?.brandAllowedCountries?.map((_, index) => <Spacing orientation="row" withEqualWidth spacing={6}>
								<DropDownContainer
									data={countryNameswithCode}
									setValue={(value) => updateBrandAllowedBrandCountry(value, values, setFieldValue, "countryCode", index)}
									value={values?.brandAllowedCountries?.[index]?.countryCode}
									label='Country'
									placeHolder='Select Country'
								/>

								<DropDownContainer
									data={paymentOptions}
									setValue={(value) => updateBrandAllowedBrandCountry(value, values, setFieldValue, "paymentOption", index)}
									value={values?.brandAllowedCountries?.[index]?.paymentOption}
									label='Payment Options'
									placeHolder='Payment Option'
								/>

								<Input
									label='Shipping Fee'
									onChangeText={(value) => updateBrandAllowedBrandCountry(value, values, setFieldValue, "shippingFee", index)}
									onBlur={handleBlur('brandAllowedCountries')}
									value={values?.brandAllowedCountries?.[index]?.shippingFee}
								// error={
								// 	touched.address2 && errors.address2
								// 		? errors.address2
								// 		: ''
								// }
								/>
								<Input
									label='Free Shipping Threshold'
									onChangeText={(value) => updateBrandAllowedBrandCountry(value, values, setFieldValue, "freeShippingThreshold", index)}
									onBlur={handleBlur('brandAllowedCountries')}
									value={values?.brandAllowedCountries?.[index]?.freeShippingThreshold}
								// error={
								// 	touched.address2 && errors.address2
								// 		? errors.address2
								// 		: ''
								// }
								/>
								<Input
									label='Exchange Rate'
									onChangeText={(value) => updateBrandAllowedBrandCountry(value, values, setFieldValue, "exchangeRate", index)}
									onBlur={handleBlur('brandAllowedCountries')}
									value={values?.brandAllowedCountries?.[index]?.exchangeRate}
								// error={
								// 	touched.address2 && errors.address2
								// 		? errors.address2
								// 		: ''
								// }
								/>
							</Spacing>)}
						</>

						<View style={{ flexDirection: 'row' }}>
							<Input
								label="Business Phone Number*"
								containerStyles={{
									flex: 0.672,
									marginRight: getResponsiveStyle(16),
								}}
								onChangeText={handleChange(
									'businessPhoneNumber'
								)}
								value={values.businessPhoneNumber}
								onBlur={handleBlur('businessPhoneNumber')}
								error={
									touched.businessPhoneNumber &&
										errors.businessPhoneNumber
										? errors.businessPhoneNumber
										: ''
								}
							/>
						</View>

						<Input
							label="VAT ID"
							containerStyles={{
								flex: 0.328,
							}}
							onChangeText={handleChange('vatId')}
							onBlur={handleBlur('vatId')}
							value={values.vatId}
							error={
								touched.vatId && errors.vatId
									? errors.vatId
									: ''
							}
						/>

						<Upload
							label={'Additional medias'}
							hint={'Add Image'}
							containerStyles={{
								width: getResponsiveStyle(128, 'dimensions'),
								height: getResponsiveStyle(136, 'dimensions'),
								marginBottom: getResponsiveStyle(24),
							}}
							uploadIcon={<PlusIcon height={14} width={14} />}
							accept={'image/*'}
							onDrop={AdditionalImage}
							loading={loading_uploadMedia}
						/>

						<Input
							label={'Numeric Signature'}
							decoration={<UploadText>{'Upload'}</UploadText>}
							onChangeText={handleChange('numericSignature')}
							onBlur={handleBlur('numericSignature')}
							value={values.numericSignature}
							error={
								touched.numericSignature &&
									errors.numericSignature
									? errors.numericSignature
									: ''
							}
						/>
						<Input
							label={'Email*'}
							onChangeText={handleChange('email')}
							onBlur={handleBlur('email')}
							value={values.email}
							error={
								touched.email && errors.email
									? errors.email
									: ''
							}
						/>

						{/* <Input
							label="Interest tags"
							onChangeText={handleChange('interestTags')}
							onBlur={handleBlur('interestTags')}
							error={
								touched.interestTags && errors.interestTags
									? errors.interestTags
									: ''
							}

						/> */}

						<Dropdown2
							isSearchable
							isMulti
							key={'dropdown'}
							options={removeDuplicateFromArrayObj(
								combinedCategoryArr
							)}
							inputProps={{
								placeholder: 'Interest tags hint',
							}}
							labelProps={{
								label: 'Interest tags*',
							}}
							values={removeDuplicateFromArrayObj(
								combinedCategoryArr
							).filter(item =>
								values.interestTags.includes(item.value)
							)}
							onChangeValue={value => {
								setFieldValue('interestTags', [
									...values.interestTags,
									value.value,
								]);
							}}
							onPressCrossOnBadge={value => {
								const filteredInterestTags =
									values.interestTags.filter(
										item => item !== value
									);

								setFieldValue(
									'interestTags',
									filteredInterestTags
								);
							}}
						/>

						{/* <Spacing withEqualWidth spacing={16}>
							<Input
								label="KYB status"
								onChangeText={handleChange('kybStatus')}
								onBlur={handleBlur('kybStatus')}
								value={values.kybStatus}
								error={
									touched.kybStatus && errors.kybStatus
										? errors.kybStatus
										: ''
								}
							/>

							<Input
								label="Account status"
								onChangeText={handleChange('accountStatus')}
								onBlur={handleBlur('accountStatus')}
								value={values.accountStatus}
								error={
									touched.accountStatus &&
										errors.accountStatus
										? errors.accountStatus
										: ''
								}
							/>
						</Spacing> */}

						<Input
							label="Website"
							onChangeText={handleChange('website')}
							onBlur={handleBlur('website')}
							value={values.website}
							error={
								touched.website && errors.website
									? errors.website
									: ''
							}
						/>

						<Input
							label="Admin note"
							onChangeText={handleChange('adminNote')}
							onBlur={handleBlur('adminNote')}
							value={values.adminNote}
							error={
								touched.adminNote && errors.adminNote
									? errors.adminNote
									: ''
							}
						/>

						<Input
							label="Description"
							multiline
							style={styles.description}
							value={values.description}
							onChangeText={handleChange('description')}
							onBlur={handleBlur('description')}
							error={
								touched.description && errors.description
									? errors.description
									: ''
							}
						/>
					</Spacing>
					{console.log({ isValid, dirty })
					}
					<Spacing orientation="column" spacing={8}>
						{!brandId ? (
							<Button
								title="Create"
								size="lg"
								onPress={handleSubmit}
								loading={createBrandLoading}
								variant={
									dirty && isValid ? 'primary' : 'disabled'
								}
							/>
						) : (
							<Button
								title="Save Changes"
								size="lg"
								onPress={handleSubmit}
								loading={editBrandLoading}
								variant={
									dirty && isValid ? 'primary' : 'disabled'
								}
							/>
						)}
						<></>
					</Spacing>

					{/* {!brandId && (
						<ModalComponent
							header="One last thing to do."
							openState={modalState}
							onClose={changeModalState}
						>
							<View style={styles.modalContainer}>
								<Text
									style={{ color: 'blue' }}
									onPress={() =>
										Linking.openURL(brandConnectUrl)
									}
								>
									Click on this link to finish the stripe
									verification process for this new brand
								</Text>
							</View>
						</ModalComponent>
					)} */}
				</>
			)}
		</Formik>
	);
};

const styles = StyleSheet.create({
	description: {
		paddingVertical: getResponsiveStyle(20),
		height: getResponsiveStyle(160, 'dimensions'),
	},
	flex1: {
		flex: 1,
	},
	modalContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	countryConfig: {
		alignItems: 'center',
		color: palettes.primary[0],
	},
});
const styleRowContainer = () => {
	return {
		flexDirection: 'row',
		marginBottom: getResponsiveStyle(24),
	} as TStyle;
};
const UploadText = styled.Text`
	font-size: ${getResponsiveStyle(12)}px;
	line-height: ${getResponsiveStyle(12)}px;
	color: ${palettes.grey[1]};
	padding-bottom: 2px;
	cursor: pointer;
	&:hover {
		border-bottom: 1px solid ${palettes.grey[1]};
	}
`;

export default AddBrandForm;
